<template>
  <section>
    <v-sheet class="pa-5">
      <div class="ma-4">STUDENT COURSES</div>
      <div class="ma-1">
        <div class="col-sm-12">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left caption">IMAGE</th>
                  <th class="text-left caption">COURSE</th>
                  <th class="text-left caption">TEACHER</th>
                  <th class="text-left caption">PROGRESS</th>
                  <th class="text-left caption"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in courses" :key="item.id">
                  <td width="5%">
                    <v-avatar size="40" v-if="item.image" tile>
                      <v-img
                        :src="`${item.aws}/courses/${item.uuid}/image/${item.image.image}`"
                      />
                    </v-avatar>
                    <!-- <v-avatar v-else color="success" size="45" tile/> -->
                  </td>
                  <td>
                    <v-list-item-title class="">
                      <div class="body-2">{{ item.title }}</div>
                    </v-list-item-title>
                  </td>
                  <td class="body-2">
                    {{ `${item.teacher.firstname} ${item.teacher.lastname}` }}
                  </td>
                  <td>
                    <v-progress-linear
                      :color="item.progress < 50 ? '#999' : 'success'"
                      rounded
                      height="20"
                      :value="item.progress"
                      stream
                    >
                      {{ Math.ceil(item.progress) }}%
                    </v-progress-linear>
                  </td>
                  <td class="" width="10%" @click="view(item)">
                    <v-btn color="info" small>View Lessons</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </v-sheet>
  </section>
</template>

<script>
export default {
  props: ["courses"],
  methods: {
    view(item) {
      console.log(item);
    }
  }
};
</script>