<template>
  <section>
    <div class="px-5 pb-5 pt-3 d-flex align-center justify-space-between" 
    :class="$vuetify.breakpoint.mdAndUp? '': 'flex-wrap'">
      
      <div class="d-flex align-center">
        <div>
          <v-avatar v-if="!student.image" size="120" tile>
            <v-img :src="require('@/assets/images/placeholder.jpg')"></v-img>
          </v-avatar>
          <v-avatar v-else>
            <v-img :src="`${student.aws}/users/${student.uuid}/image/${student.image.image}`"></v-img>
          </v-avatar>
        </div>
        <div class="ml-5">
          <div class="text-h5" :class="$vuetify.breakpoint.mobile?'':''">
            {{`${student.firstname} ${student.lastname}`}}
          </div>
          <span class="ml-1">{{student.email}}</span>
        </div>
      </div>

      <v-sheet class="mx-5 d-flex flex-column align-end">
        <div class="d-flex flex-wrap" :class="$vuetify.breakpoint.mobile? 'mt-5':''">
          <div :class="$vuetify.breakpoint.mobile?'mr-5':''">
            <div class="">{{student.prc_number}}</div>
            <span class="caption">Prc No. </span> 
          </div>
          <v-divider class="mx-5 hidden-sm-and-down" vertical/>
          <div :class="$vuetify.breakpoint.mobile?'mr-5':''">
            <div class="">{{student.contact}}</div>
            <span class="caption">Contact No. </span>
          </div>
          <v-divider class="mx-5 hidden-sm-and-down" vertical/>
          <div class="">
            <div class="">{{`${student.city}, ${student.state} ${student.country}`}}</div>
            <span class="caption">Address </span>
          </div>
        </div>
      </v-sheet>
    </div>
    <v-divider/>
    <v-sheet class="ma-5" v-if="student.specialty || student.subspecialty">
      <div class="pb-5">
        <div class="ml-1 mb-5">OTHERS</div>
        <div class="ml-2 mb-5" v-if="student.specialty">
          <div class="caption ml-1 mb-1">SPECIALTY</div>
          <v-chip v-for="item in JSON.parse(student.specialty)" :key="item">
          {{ item }}
        </v-chip>
        </div>
        <div class="ml-2" v-if="student.subspecialty">
          <div class="caption ml-1 mb-1">SUBSPECIALTY</div>
          <v-chip v-for="item in JSON.parse(student.subspecialty)" :key="item">
          {{ item }}
        </v-chip>
        </div>
      </div>
    </v-sheet>
  </section>
</template>

<script>
export default {
  props: ['student']
}
</script>