<template>
  <section>
    <v-row v-if="!loading">
      <v-col cols="12" xl="8" lg="10">
        <v-sheet>
          <div class="pa-3 d-flex align-center">
            <v-btn icon @click="$router.back()">
              <v-icon size="35">mdi-arrow-left</v-icon>
            </v-btn>
            <div class="ml-3">
              {{ $helpers.level(student.level) }}
            </div>
          </div>
          <StudentInfo :student="student" />
        </v-sheet>
        <StudentCourses :courses="courses" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import StudentInfo from "../components/account/StudentInfo.vue";
import StudentCourses from "../components/account/StudentCourses.vue";

export default {
  components: {
    StudentInfo,
    StudentCourses,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState("admin", {
      student: (state) => state.student,
      courses: (state) => state.courses,
    }),
  },
  created() {
    this.showStudentAction(this.$route.params.uuid).then(() => {
      this.loading = false;
      this.studentProgressAction(this.student.id);
    });
  },
  methods: {
    ...mapMutations("admin", ["coursesMutation"]),
    ...mapActions("admin", ["showStudentAction", "studentProgressAction"]),
  },
  beforeDestroy() {
    this.coursesMutation([]);
  },
};
</script>